<template>
  <div class="pageStyle">
    <Header />
    <div class="mainContainer">
      <div class="LoginAndJoinBox">
        <IconsWorker icon="fingerPrint" class="headerIcon" width="50"/>
        <div class="title">
          {{ state.page === 'join' ? 'إنشاء حساب جديد' : 'تسجيل الدخول إلى حسابك' }}
        </div>
        <template v-if="state.page === 'login'">
          <DuInput name="usernameOrEmail" :is-required="true" type="text" label="اسم المستخدم أو البريد" :get-ref="(e) => createFormRef(e)"/>
          <DuInput name="password" :is-required="true" type="password" label="كلمة المرور"  :get-ref="(e) => createFormRef(e)"/>
          <router-link class="didYouForgotPasswordText" to="/resetPassword">
            <span>نسيت كلمة المرور؟</span>
            <span class="clickHere">انقر هنا لإستعادتها</span>
          </router-link>
        </template>
        <template v-if="state.page === 'join'">
          <DuInput name="name" :is-required="true" type="text" label="الاسم" :get-ref="(e) => createFormRef(e)"/>
          <DuInput name="username" :is-required="true" type="text" label="اسم المستخدم" :get-ref="(e) => createFormRef(e)"/>
          <DuInput name="email" :is-required="true" type="email" type-error-message="البريد الإلكتروني غير صحيح" label="البريد الإلكتروني" :get-ref="(e) => createFormRef(e)"/>
          <DuInput name="password" :is-required="true" type="password" label="كلمة المرور"  :get-ref="(e) => createFormRef(e)"/>
          <div class="termsAndPrivacy">
            <input type="checkbox" v-model="state.isEcceptTerms"/>
            <span>بالتسجيل فأنت توافق على </span>
            <RouterLink to="/terms">شروط الإستخدام</RouterLink>
            <span> و </span>
            <RouterLink to="/privacy">سياسة الخصوصية</RouterLink>
          </div>
        </template>
        <DuButton :text="state.page === 'join' ? 'تسجيل حساب جديد' : 'تسجيل الدخول'" class="mainButton" @click="send()" background="#FF0029" :disabled="checkIfDesabled()"/>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import IconsWorker from "../components/IconsWorker";
import DuInput from "../components/DuInput";
import DuButton from "../components/DuButton";
import FixedNotification from "../components/plugins/fixedNotifications/FixedNotification";
import AjaxWorker from "../helpers/AjaxWorker";
import {onBeforeMount, onMounted, reactive, watch} from "vue";
import myAccountAsyncStore from "../store/myAccountAsyncStore";
import router from "../router";
import Header from "@/components/Header";
import {useRoute} from "vue-router";
import Footer from "@/components/Footer";
import BrowserHelper from "@/helpers/BrowserHelper";

export default {
  name: "LoginAndJoin",
  components: {Footer, Header, DuButton, DuInput, IconsWorker},
  setup() {
    const state = reactive({
      username: { isValid: false, value: null },
      password:  { isValid: false, value: null },
      isLoading: false,
      page: 'login',
      formRefs: {},
      isEcceptTerms: true,
    });
    const route = useRoute();
    const initPage = () => {
      state.formRefs = {};
      if(route.path.includes("join")) {
        state.page = "join";
        BrowserHelper.changeTitle('إنشاء حساب');
      } else {
        state.page = "login";
        BrowserHelper.changeTitle('تسجيل الدخول');
      }
    }
    onBeforeMount(() => {
      initPage();
    });
    onMounted(() => {
      if(myAccountAsyncStore.getters.isLogin) {
        router.replace('/controlPanel/applys');
      }
    });
    watch(() => route.path, () => {
      initPage();
    });
    const send = () => {

      let isFormValid = true;
      const _params = {};
      Object.keys(state.formRefs).forEach((key) => {
        const el = state.formRefs[key];
        el.validateNow();
        if(!el.state.isValid) {
          isFormValid = false;
        }
        _params[el.state.name] = el.state.innerValue;
      });

      if(isFormValid) {
        state.isLoading = true;
        let _action = "login";
        if(state.page === "join")
          _action = "join";

        new AjaxWorker().request('/transactions.php?action=' + _action, _params).then((res) => {
          console.log(res);
          state.isLoading = false;
          if(res === "NOINTERNETCONNECTION") {
            FixedNotification.create({
              title: 'حدث خطاء أثناء الإتصال',
              decription: 'تأكد من إتصالك بالإنترنت',
              icon: 'wifiF',
              color: 'red',
              hideAfter: 3000
            });
          } else {
            if(res.state) {

              if(state.page === "join") {
                FixedNotification.create({
                  decription: "تم إنشاء الحساب بنجاح",
                  icon: "doneF",
                  color: 'green',
                  hideAfter: 3000
                });
              }

              myAccountAsyncStore.dispatch('login', { id: res.id, authKey: res.authKey }).then(() => {
                if(state.page === "join") {
                  router.replace('/accountActivation');
                } else {
                  router.replace('/controlPanel/applys');
                }
              });
            } else {
              let errorMessage = 'خطاء غير معلوم حاول مرة أخرى';
              let errorIcon = 'errorF';
              if(res.reason === "username_or_email_not_exist") {
                errorMessage = "لم يتم العثور على الحساب";
                errorIcon = "userF";
              } else if(res.reason === "password_is_invalid") {
                errorMessage = "كلمة المرور غير صحيحة";
                errorIcon = "roundLockF";
              } else if(res.reason === "account_is_blocked") {
                errorMessage = "تم تقييد وصول هذا الحساب";
                errorIcon = "roundLockF";
              } else if(res.reason === "email_is_already_used") {
                errorMessage = "هذا البريد مستخدم مسبقًا";
                errorIcon = "emailF";
              } else if(res.reason === "phone_is_already_used") {
                errorMessage = "رقم الهاتف مستخدم مسبقًا";
                errorIcon = "roundLockF";
              } else if(res.reason === "username_is_already_used") {
                errorMessage = "رقم الهاتف مستخدم مسبقًا";
                errorIcon = "callF";
              }
              FixedNotification.create({
                decription: errorMessage,
                icon: errorIcon,
                color: 'red',
                hideAfter: 3000
              });
            }
          }
        });
      }
      /*
      FixedNotification.create({
        title: 'fff',
        decription: 'wwwww',
        icon: 'roundLock',
        color: 'red',
        hideAfter: 3000
      });
      */
    }
    const createFormRef = (ref) => {
      state.formRefs[ref.state.name] = ref;
    };
    const checkIfDesabled = () => {
      if(state.page === "join") {
        if(!state.isEcceptTerms) {
          return true;
        } else {
          false;
        }
      }
      return false;
    };
    return {
      createFormRef,
      send,
      checkIfDesabled,
      state,
    }
  }
}
</script>

<style scoped>
.pageStyle{
  background: #eee;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
}
.LoginAndJoinBox{
  width: 35%;
  margin: 150px 27.5%;
  background: #fff;
  padding: 30px 2.5%;
  border-radius: 10px;
  box-shadow: 0 0 20px #ccc;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.title{
  font-size: 16px;
  color: #222;
  font-weight: bold;
  margin-bottom: 20px;
}
.headerIcon{
  align-self: center;
}
.mainButton{
  align-self: end;
}

.didYouForgotPasswordText{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: start;
  font-size: 14px;
  color: #555;
  padding: 5px 0;
  cursor: pointer;
  gap: 5px;
}
.didYouForgotPasswordText:hover{opacity: 0.7}
.didYouForgotPasswordText:active{opacity: 0.5}
.didYouForgotPasswordText .clickHere{font-weight: bold; color: #FF0029;}

.termsAndPrivacy{
  font-size: 14px;
  text-align: start;
}
.termsAndPrivacy span{
  margin: 0 1px;
}
.termsAndPrivacy a{
  color: #FF0029;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .LoginAndJoinBox{
    width: 90%;
    background: #fff;
    padding: 30px 5%;
    margin: 80px 0;
  }
}

</style>